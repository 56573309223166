.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contentArea {
  padding: 24px;
  background: #fff;
  min-height: 360px;
}

.detailTalbe {
  padding-bottom: 25px;
}
.detailTalbe .rightTitle {
  text-align: right;
  font-weight: bold;
}

.editButton {
  border-color: rgb(0, 228, 38);
  color: rgb(0, 228, 38);
}

.exelButton {
  border-color: rgb(0, 151, 25);
  color: rgb(0, 151, 25);
}

.warningButton {
  background: #ffeb3b;
  color: #000;
}
.warningButton:hover {
  border-color: #fff281 !important;
  background: #fff281 !important;
  color: rgb(158, 158, 158) !important;
}
.successButton {
  background: rgb(0, 151, 25);
  color: #fff;
}
.successButton:hover {
  border-color: rgb(102, 190, 117) !important;
  background: rgb(102, 190, 117) !important;
  color: rgb(179, 226, 187) !important;
}

.colorPicker .ant-input-group-addon {
  background-color: #ffffff;
}
