/*
a:link {text-decoration: none; color: rgb(95, 6, 40);}
a:visited {text-decoration: none; color: rgb(128, 0, 32);}
a:active {text-decoration: none; color: grey;}
a:hover {text-decoration: underline; color: red;}
*/
a:link {
  text-decoration: none;
  color: rgb(14, 115, 247);
}
a:visited {
  text-decoration: none;
  color: rgb(14, 115, 247);
}
a:active {
  text-decoration: none;
  color: grey;
}
a:hover {
  text-decoration: underline;
  color: red;
}

.dashboard-list td,
.dashboard-list th {
  padding: 10px 0 !important;
}
